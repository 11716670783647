import type { GetStaticProps, NextPage } from "next";
import React from "react";
import { Center, ContainerWhite } from "../components/basic";
import { getTranslations } from "../lib/translations";
import { useTranslations } from "next-intl";

const Error: NextPage = () => {
	const t = useTranslations();
	return (
		<ContainerWhite>
			<Center>
				<h1>{t("message")}</h1>
			</Center>
		</ContainerWhite>
	);
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
	const messages = await getTranslations(locale, ["404", "common"]);
	return {
		props: {
			messages,
		},
	};
};

export default Error;
